import { FormControl, TextField, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, Typography } from "../style";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs"; // Assicurati di avere 'dayjs' installato

export const parseDate = (dateString) => {
  if (!dateString) return null;
  const [day, month, year] = dateString.split("/");
  return new Date(year, month - 1, day);
};

export const formatDate = (date) => {
  if (!date) return "";
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const DynamicForm = ({ dataset, section, sectName, name, isAccordion }) => {
  const [formState, setFormState] = useState({
    values: {},
    errors: {},
  });
  const theme = localStorage.getItem("theme");

  useEffect(() => {
    const initialValues = dataset.reduce(
      (acc, field) => ({
        ...acc,
        [field.label]: field.value || "",
      }),
      {}
    );

    const sectionValues =
      section?.dataset.reduce(
        (acc, field) => ({
          ...acc,
          [field.label]: field.value || "",
        }),
        {}
      ) || {};

    setFormState({
      values: { ...initialValues, ...sectionValues },
      errors: {},
    });
  }, [dataset, section]);

  const validateField = (field, value) => {
    let error = "";
    if (field.regex && value && !new RegExp(field.regex).test(value)) {
      error = field.errRegex || "Formato non corretto";
    } else if (field.required && !value) {
      error = `${field.label} è un campo obbligatorio`;
    }
    return error;
  };

  const handleFieldChange = (fieldName, value, datasetType = "main") => {
    const datasetToUse = datasetType === "main" ? dataset : section?.dataset;
    const field = datasetToUse?.find((el) => el.label === fieldName);

    if (field) {
      const error = validateField(field, value);

      setFormState((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          [fieldName]: value,
        },
        errors: {
          ...prevState.errors,
          [fieldName]: error || undefined,
        },
      }));
    }
  };

  const handleDatePickerChange = (newValue, fieldName) => {
    if (newValue && dayjs(newValue).isValid()) {
      const formattedDate = dayjs(newValue).format("DD/MM/YYYY");
      handleFieldChange(fieldName, formattedDate);
    } else {
      handleFieldChange(fieldName, "");
    }
  };

  const renderInputField = (data, datasetType = "main") => {
    const value = formState.values[data.label] ?? "";
    const error = formState.errors[data.label];
    const hasError = !!error;

    if (data.type === "date") {
      return (
        <DatePicker
          label={`${data.label} ${data.required ? "*" : ""}`}
          value={value ? dayjs(parseDate(value)) : null}
          onChange={(newValue) => handleDatePickerChange(newValue, data.label)}
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={!data.editable}
              fullWidth
              error={hasError}
              helperText={hasError ? error : ""}
            />
          )}
          disableFuture
          openTo="year"
          views={["year", "month", "day"]}
        />
      );
    } else {
      return (
        <TextField
          disabled={!data.editable}
          label={`${data.label} ${data.required ? "*" : ""}`}
          name={data.label}
          value={value}
          onChange={(event) =>
            handleFieldChange(data.label, event.target.value, datasetType)
          }
          fullWidth
          sx={
            theme === '"LIGHT"' && {
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "black",
              },
              "& .MuiInputLabel-root.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.6)",
              },
            }
          }
          error={hasError}
          helperText={hasError ? error : ""}
        />
      );
    }
  };

  const validateForm = () => {
    const newErrors = {};

    dataset.forEach((el) => {
      const error = validateField(el, formState.values[el.label]);
      if (error) {
        newErrors[el.label] = error;
      }
    });

    section?.dataset?.forEach((el) => {
      const error = validateField(el, formState.values[el.label]);
      if (error) {
        newErrors[el.label] = error;
      }
    });

    setFormState((prevState) => ({
      ...prevState,
      errors: newErrors,
    }));

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      console.log("Form submitted successfully", formState.values);
    } else {
      console.log("Form contains errors", formState.errors);
    }
  };

  const isBtnDisabled =
    !dataset.some((el) => el.editable) &&
    !section?.dataset?.some((el) => el.editable);

  return (
    <Grid container spacing={3}>
      {!isAccordion && (
        <Grid item xs={12}>
          <Typography my={6} variant="h6">
            {name}
          </Typography>
        </Grid>
      )}
      <Grid container item spacing={3}>
        {dataset.map((el) => (
          <Grid item xs={12} sm={4} key={el.label}>
            <FormControl fullWidth>{renderInputField(el, "main")}</FormControl>
          </Grid>
        ))}
      </Grid>

      {section && (
        <Grid item xs={12}>
          <Box sx={{ translate: "12px 0" }}>
            <Divider my={6} />
            <Typography my={6}>{sectName}</Typography>
            <Grid container spacing={3}>
              {section.dataset.map((el) => (
                <Grid item xs={12} sm={4} key={el.label}>
                  <FormControl fullWidth>
                    {renderInputField(el, "section")}
                  </FormControl>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      )}

      <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="outlined"
          mt={5}
          ml={3}
          disabled={isBtnDisabled}
          onClick={handleSubmit}
        >
          Salva
        </Button>
      </Grid>
    </Grid>
  );
};

export default DynamicForm;
