import { Add } from "@mui/icons-material";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  Divider as MuiDivider,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import Modale from "./Modale";
import { DataGrid } from "@mui/x-data-grid";
import { columnsDatagrid } from "./columnsDatagrid";
import Filters from "./filtri/Filters";
import { Data } from "./filtri/Data";
import Charts from "./charts/Charts";

const Divider = styled(MuiDivider)(spacing);

const Visure = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const theme = useTheme();
  const isUnder600px = useMediaQuery("(max-width:600px)");
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const columns = columnsDatagrid();

  const {
    visureRows,
    totalRows,
    pageNumbers,
    rowsPerPage,
    handleApplyFilters,
    handleRemoveFilters,
    filtersLoading,
    isRemovingFilters,
    handlePageChange,
    handlePageSizeChange,
    barChartData,
    canClearFilters,
    clearTextField,
  } = Data();

  const handleClickModal = () => {
    setIsModalOpen(true);
  };

  return (
    <React.Fragment>
      <Helmet title="Micro Servizi" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3" display="inline">
          Visure
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 4,
            alignItems: "center",
          }}
        >
          <Tooltip title="Ricerca">
            <Button variant="contained" onClick={handleClickModal}>
              <Add />
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Divider my={4} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Charts barChartData={barChartData} totalRows={totalRows} />
        </Grid>
        <Grid item xs={12}>
          <Filters
            filtersLoading={filtersLoading}
            handleApplyFilters={handleApplyFilters}
            handleRemoveFilters={handleRemoveFilters}
            isRemovingFilters={isRemovingFilters}
            canClearFilters={canClearFilters}
            clearTextField={clearTextField}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={6}>
            <Paper>
              <div style={{ width: "100%", height: 400 }}>
                <DataGrid
                  onPageSizeChange={handlePageSizeChange}
                  onPageChange={(params) => handlePageChange(params)}
                  getRowId={(row) => row.idUID}
                  rowsPerPageOptions={[5, 10, 20]}
                  pagination
                  paginationMode="server"
                  pageSize={rowsPerPage}
                  page={pageNumbers - 1}
                  rowCount={totalRows}
                  rows={visureRows}
                  columns={columns}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        // Hide columns status and traderName, the other columns will remain visible
                        idUID: false,
                      },
                    },
                  }}
                />
              </div>
            </Paper>
          </Card>
        </Grid>
      </Grid>
      <Modale open={isModalOpen} setOpen={setIsModalOpen} />
    </React.Fragment>
  );
};

export default Visure;
