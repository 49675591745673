import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Divider as MuiDivider,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { DatePicker } from "@mui/x-date-pickers";
import Tariffe from "./Tariffe";
import Opzioni from "./Opzioni";

const Divider = styled(MuiDivider)(spacing);

const form = [
  {
    label: "N° Polizza",
    type: "textfield",
  },
  {
    label: "Nome",
    type: "textfield",
  },
  {
    label: "Codice",
    type: "textfield",
  },
  {
    label: "Tipo",
    type: "select",
    id: "tipo",
    items: [{ label: "Convenzione" }],
  },
  {
    label: "Compagnia",
    type: "select",
    id: "compagnia",
    items: [{ label: "Assicuratrice Milanese" }],
  },
  {
    label: "Tipo Veicolo",
    type: "select",
    id: "tipoVeicolo",
    items: [{ label: "Autocarro" }],
  },
  {
    label: "Carico Scarico",
    type: "select",
    id: "caricoScarico",
    items: [{ label: "No" }, { label: "Si" }],
  },
  {
    label: "Fornitore",
    type: "select",
    id: "fornitore",
    items: [{ label: "Broker Genesi Srl" }],
  },
  {
    label: "Tipo Guida",
    type: "select",
    id: "tipoGuida",
    items: [{ label: "Guida Libera" }],
  },
  {
    label: "Massimale",
    type: "textfield",
    type2: "icon",
  },
  {
    label: "Scadenza",
    type: "textfield",
    type2: "date",
  },
];

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

function LibriM() {
  const [scadenza, setScadenza] = useState(null);
  const [valueTabs, setValueTabs] = useState(0);

  const handleTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  return (
    <React.Fragment>
      <Helmet title="Libri matricola" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4" display="inline">
          Libri matricola
        </Typography>
      </Box>
      <Divider my={4} />
      <Card sx={{ marginBottom: "16px" }}>
        <CardContent>
          <Grid
            container
            spacing={2}
            wrap="wrap"
            sx={{ maxWidth: { xs: "calc(100vw - 60px)" } }}
          >
            {form.map((element) =>
              element.type === "textfield" ? (
                <Grid item sm={3} xs={12} key={element.label}>
                  {element.type2 === "icon" ? (
                    <FormControl fullWidth size="small" margin="none">
                      <TextField
                        inputProps={{
                          pattern: "^\\+?[0-9]{7,15}$",
                        }}
                        id={element.id}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">€</InputAdornment>
                          ),
                        }}
                        label={element.label}
                      />
                    </FormControl>
                  ) : element.type2 === "date" ? (
                    <FormControl fullWidth size="small" margin="none">
                      {/*  <InputLabel htmlFor={element.id}>
                        {element.label}
                      </InputLabel> */}
                      <DatePicker
                        required
                        renderInput={(props) => <TextField {...props} />}
                        label={element.label}
                        inputFormat="dd/MM/yyyy"
                        value={scadenza}
                        onChange={(newValue) => {
                          setScadenza(newValue);
                        }}
                      />
                    </FormControl>
                  ) : (
                    <TextField
                      size="small"
                      margin="none"
                      label={element.label}
                      fullWidth
                      required
                    />
                  )}
                </Grid>
              ) : (
                <Grid item sm={3} xs={12} key={element.id}>
                  <TextField
                    id={element.id}
                    fullWidth
                    select
                    label={element.label}
                    /* defaultValue="" */
                  >
                    {element.items.map((item) => (
                      <MenuItem key={item.label} value={item.label}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )
            )}
          </Grid>
        </CardContent>
      </Card>

      <Tabs value={valueTabs} onChange={handleTabs} sx={{ marginTop: "4px" }}>
        <Tab label="Tariffe" />
        <Tab label="Opzioni" />
      </Tabs>
      <Card sx={{ marginTop: "16px" }}>
        <CardContent>
          <CustomTabPanel value={valueTabs} index={0}>
            <Tariffe />
          </CustomTabPanel>
          <CustomTabPanel value={valueTabs} index={1}>
            <Opzioni />
          </CustomTabPanel>
        </CardContent>
      </Card>
      <Box sx={{ display: "flex", justifyContent: "end", marginTop: "16px" }}>
        <Button variant="contained">Salva</Button>
      </Box>
    </React.Fragment>
  );
}
export default LibriM;
