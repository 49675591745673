import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Box,
  Popover as MuiPopover,
  Typography,
  Button as MuiButton,
  CircularProgress,
} from "@mui/material";
import {
  generalErrorMessage,
  rebootMessage,
  savedDataMessage,
} from "../../rcAuto/constant";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../../redux/slices/snackbar";
import { useNavigate } from "react-router-dom";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    padding: 16px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const ContentBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
`;

const ButtonBox = styled(Box)`
  display: flex;
  gap: "16px";
  width: 100%;
  justify-content: center;
  margin-top: 16px;
`;

const Button = styled(MuiButton)`
  margin: 0 8px;
`;

const PopoverRefresh = ({
  idUID,
  scope,
  isRebootPopoverOpen,
  handleCloseRebootPopup,
  rebootRef,
  setDataArray,
  servizio,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSnackbar = (message, severity) => {
    dispatch(showSnackbar({ message: message, severity: severity }));
  };

  async function servicesNewUpdate(data) {
    const token = sessionStorage.getItem("token");

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          data: data,
          action: "reboot",
        }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/servicesNewUpdate",
        requestOptions
      );

      const responseData = await response.json();
      if (responseData.status === 1) {
        handleSnackbar(responseData.message, "success");
      } else {
        handleSnackbar(responseData.message, "error");
      }
    } catch (error) {
      return error;
    }
  }

  const handleReboot = () => {
    const data = {
      idUID,
      scope,
    };
    setLoading(true);
    servicesNewUpdate(data);
    servicesGetDetails({
      codice: servizio,
      scope: scope,
    });
    setLoading(false);
    handleCloseRebootPopup();
  };

  async function servicesGetDetails(data) {
    const token = sessionStorage.getItem("token");
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          data: data,
        }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/servicesGetDetails",
        requestOptions
      );

      const responseData = await response.json();
      if (responseData.status === -1) {
        navigate("/auth/sign-in");
      }
      const array = responseData.data.dataset;
      setDataArray(array);
    } catch (error) {
      return error;
    }
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorEl={rebootRef}
      onClose={handleCloseRebootPopup}
      open={isRebootPopoverOpen}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "8px 0",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <ContentBox>
          <FlexBox>
            <Typography variant="body2" sx={{ marginTop: "8px" }}>
              Si desidera eseguire nuovamente la ricerca per la targa
              selezionata?
            </Typography>
          </FlexBox>
          <ButtonBox>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCloseRebootPopup}
            >
              No
            </Button>
            <Button variant="contained" color="primary" onClick={handleReboot}>
              Si
            </Button>
          </ButtonBox>
        </ContentBox>
      )}
    </Popover>
  );
};

export default PopoverRefresh;
