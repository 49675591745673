import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import React, { useState } from "react";
import { Typography } from "@mui/material";

const initialRows = [
  {
    id: 1,
    tipoCosto: "Conto Terzi",
    cavalliDa: 35,
    cavalliA: 60,
    classeDa: 1,
    classeA: 14,
    prezzo: 1000,
  },
  {
    id: 2,
    tipoCosto: "Conto Terzi",
    cavalliDa: 35,
    cavalliA: 60,
    classeDa: 1,
    classeA: 14,
    prezzo: 1000,
  },
];

const Tabella = () => {
  const [rows, setRows] = useState(initialRows);
  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "id",
      headerName: "#",
      width: 50,
    },
    {
      field: "tipoCosto",
      headerName: "Tipo Costo",
      width: 150,
      headerAlign: "center",
      align: "right",
      editable: true,
    },
    {
      field: "cavalliDa",
      headerName: "Cavalli Da",
      width: 150,
      headerAlign: "center",
      align: "right",
      editable: true,
    },
    {
      field: "cavalliA",
      headerName: "Cavalli A",
      width: 150,
      headerAlign: "center",
      align: "right",
      editable: true,
    },
    {
      field: "classeDa",
      headerName: "Classe Da",
      width: 150,
      headerAlign: "center",
      align: "right",
      editable: true,
    },
    {
      field: "classeA",
      headerName: "Classe A",
      width: 150,
      headerAlign: "center",
      align: "right",
      editable: true,
    },
    {
      field: "prezzo",
      headerName: "Prezzo",
      width: 150,
      headerAlign: "center",
      align: "right",
      editable: true,
      renderCell: (params) => {
        const { prezzo } = params.row;
        return <Typography>€{prezzo}</Typography>;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<CheckIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div style={{ width: "100%", height: "300px" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        rowsPerPageOptions={[5, 10, 20]}
        allowUpdating={true}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </div>
  );
};

export default Tabella;
