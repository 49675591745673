import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../../../redux/slices/snackbar";

const NuovoServizio = ({ open, setOpen, refresh }) => {
  const [scope, setScope] = useState("");
  const [scopeCodice, setScopeCodice] = useState();
  const [servizio, setServizio] = useState("");
  const [stato, setStato] = useState("");
  const [statoCodice, setStatoCodice] = useState();
  const [nameError, setNameError] = useState(false);
  const dispatch = useDispatch();

  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
    setStato("");
    setScope("");
    setScopeCodice(null);
    setStatoCodice(null);
    setServizio("");
  };

  const handleChangeServizio = (e) => {
    setServizio(e.target.value);
    if (e.target.validity.valid) {
      setNameError(false);
    } else {
      setNameError(true);
    }
  };

  const handleClickScope = (s) => {
    setScopeCodice(s);
  };

  const handleClickStato = (s) => {
    setStatoCodice(s);
  };

  const handleChangeScope = (e) => {
    setScope(e.target.value);
  };

  const handleChangeStato = (e) => {
    setStato(e.target.value);
  };

  const handleCrea = () => {
    //Validazione
    if (!nameError) {
      servicesNewUpdate({
        scope: scopeCodice,
        nome: servizio,
        idStato: statoCodice,
      });
    } else {
      dispatch(
        showSnackbar({
          message: "Nome servizio non valido! Usa solo lettere e numeri.",
          severity: "error",
        })
      );
    }
  };

  const handleSnackbar = (message, severity) => {
    dispatch(showSnackbar({ message: message, severity: severity }));
  };

  async function servicesNewUpdate(data) {
    const token = sessionStorage.getItem("token");

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          data: data,
          action: "newService",
        }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/servicesNewUpdate",
        requestOptions
      );

      const responseData = await response.json();
      if (responseData.status === 1) {
        handleSnackbar(responseData.message, "success");
        setOpen(false);
        refresh();
      } else {
        handleSnackbar(responseData.message, "error");
      }
    } catch (error) {
      return error;
    }
  }

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            backgroundColor:
              theme.palette.mode === "dark" ? "#1B2635" : "#F7F9FC",
          },
        },
      }}
    >
      <DialogTitle>Crea nuovo servizio</DialogTitle>
      <DialogContent>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <FormControl
            m={2}
            sx={{ mt: 2 }}
            style={{ minWidth: 120 }}
            size="small"
          >
            <InputLabel id="scope">Scope</InputLabel>
            <Select
              labelId="scope"
              id="scope"
              label="Scope"
              value={scope}
              onChange={handleChangeScope}
              size="small"
            >
              <MenuItem
                onClick={() => handleClickScope(0)}
                sx={{ height: 30 }}
                value="Preventivi"
              >
                Preventivi
              </MenuItem>
              <MenuItem
                onClick={() => handleClickScope(2)}
                sx={{ height: 30 }}
                value="Ania"
              >
                Ania
              </MenuItem>
              <MenuItem
                onClick={() => handleClickScope(1)}
                sx={{ height: 30 }}
                value="Asset"
              >
                Asset
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl m={2} style={{ minWidth: 120 }} size="small">
            <TextField
              size="small"
              label="Servizio"
              onChange={handleChangeServizio}
              inputProps={{
                pattern: "^[a-zA-Z0-9_]{3,30}$",
              }}
              error={nameError}
              helperText={nameError ? "Min 3, solo lettere e numeri" : ""}
            />
          </FormControl>
          <FormControl m={2} style={{ minWidth: 120 }} size="small">
            <InputLabel id="stato">Stato</InputLabel>
            <Select
              labelId="stato"
              id="stato"
              label="Stato"
              value={stato}
              onChange={handleChangeStato}
              size="small"
            >
              <MenuItem
                onClick={() => handleClickStato(1)}
                sx={{ height: 30 }}
                value="Attivo"
              >
                Attivo
              </MenuItem>
              <MenuItem
                onClick={() => handleClickStato(0)}
                sx={{ height: 30 }}
                value="Non Attivo"
              >
                Non Attivo
              </MenuItem>
            </Select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          sx={{ marginRight: "8px" }}
        >
          Chiudi
        </Button>
        <Button
          onClick={handleCrea}
          variant="contained"
          sx={{ marginRight: "8px" }}
        >
          Crea
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NuovoServizio;
