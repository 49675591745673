import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  useMediaQuery,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip as MuiChip,
  Stack,
} from "@mui/material";
import { styled, useTheme } from "@mui/system";
import { ExpandMore, Image } from "@mui/icons-material";
import { Button } from "../style";

import { PictureAsPdfSharp } from "@mui/icons-material";

const formatPrice = (price) => {
  return parseFloat(price).toLocaleString("it-IT", {
    style: "currency",
    currency: "EUR",
  });
};

const CardContainer = styled(Box)(({ theme, isExpanded }) => ({
  width: "100%",
  transition: "all .3s linear",
  borderRadius: 0,
  height: "auto",
  overflow: "hidden",
  border: "2px solid transparent",
  borderBottomColor:
    theme.name === "LIGHT" ? theme.palette.divider : "rgba(255, 255, 255, 0.5)",

  [theme.breakpoints.down("sm")]: {
    border: "2px solid rgba(255, 255, 255, 0.5)",
  },

  "&:hover": {
    border: "2px solid #FE6A00",
  },
}));

const FullWidthCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  // alignItems: "center",
  borderRadius: 0,
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
  position: "relative",
}));

const KeyValueRow = styled(Box)(({ theme, isEven }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1),
  backgroundColor: isEven
    ? theme.name === "LIGHT"
      ? "#e6e6e6"
      : "rgba(255,255,255,.05)"
    : "transparent",
}));

const SmallButton = styled(IconButton)(({ theme, isActive }) => ({
  backgroundColor: isActive ? "#FE6A00" : "transparent",
  color: isActive ? "white" : "inherit",
  fontSize: "0.75rem",
  borderRadius: 0,
  padding: theme.spacing(0.5, 1),
  "&:hover": {
    backgroundColor: "#FE6A00",
    color: "white",
  },
}));

const Chip = styled(MuiChip)`
  height: 18px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"]};
  color: ${(props) => props.theme.palette.common.white};
`;

const ListComponent = ({ dataset }) => {
  const [expandedItem, setExpandedItem] = useState(null);
  const [contentType, setContentType] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Sort dataset by Prezzo_Totale
  const sortedDataset = dataset
    ? [...dataset].sort(
        (a, b) => parseFloat(a.Prezzo_Totale) - parseFloat(b.Prezzo_Totale)
      )
    : [];

  const handleExpand = (id, type) => {
    if (expandedItem === id && contentType === type) {
      setExpandedItem(null);
      setContentType(null);
    } else {
      setExpandedItem(id);
      setContentType(type);
    }
  };

  return (
    <Grid container direction="column">
      {dataset &&
        sortedDataset.map((item) => {
          const isExpanded = expandedItem === item.idUID;

          return (
            <Grid item key={item.idUID}>
              <CardContainer isExpanded={isExpanded} mb={4}>
                <FullWidthCard>
                  {/* Left Section: Image and Compagnia */}
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    pt={2}
                    sx={{
                      flexBasis: isMobile ? "100%" : "15%",
                      minWidth: "220px",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      textAlign="center"
                      mb={1}
                      px={1}
                    >
                      {item.Compagnia}
                    </Typography>
                    {item.image ? (
                      <img
                        src={`/static/img/companies/${item.image}`}
                        alt={item.Compagnia}
                        style={{
                          width: "110px",
                          height: "110px",
                          bgcolor: "gray",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <Image
                        alt={item.Compagnia}
                        src={item.logo || ""}
                        sx={{
                          width: 80,
                          height: 80,
                          bgcolor: "gray",
                          marginBottom: 1,
                        }}
                      />
                    )}
                  </Box>

                  {/* Center Section: KeyValueRow */}
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      padding: 2,
                      flexBasis: isMobile ? "100%" : "60%",
                    }}
                  >
                    {Object.entries(item)
                      .filter(([key]) =>
                        [
                          "Massimale",
                          "Tipo di guida",
                          "Include",
                          "Numero di preventivo",
                          "Note",
                          "Accordi",
                        ].includes(key)
                      )
                      .map(([key, value], idx) => (
                        <KeyValueRow key={key} isEven={idx % 2 === 0}>
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            fontWeight="bold"
                          >
                            {key}:
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            textAlign="end"
                          >
                            {key === "Tipo di guida" ? (
                              <Chip
                                label={value}
                                color={
                                  value === "Guida Libera"
                                    ? "success"
                                    : "warning"
                                }
                              />
                            ) : (
                              value || "-"
                            )}
                          </Typography>
                        </KeyValueRow>
                      ))}
                  </CardContent>

                  {/* Right Section: Price and Button */}
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    pt={1}
                    sx={{
                      flexBasis: isMobile ? "100%" : "15%",
                      textAlign: "center",
                      minWidth: "220px",
                      justifyContent: "space-between",
                      height: "100px",
                      // background: "yellow",
                    }}
                  >
                    <Typography
                      variant="h2"
                      fontWeight="bold"
                      color="#FE6A00"
                      mb={4}
                    >
                      {formatPrice(item.Prezzo_Totale)}
                    </Typography>
                    <Button
                      sx={{
                        background: "#5cb85c",
                        color: "white",
                        width: "80%",
                        fontSize: "1rem",

                        "&:hover": {
                          background: "#499149",
                        },
                      }}
                    >
                      VAI
                    </Button>
                  </Box>
                </FullWidthCard>
                <Box display="flex" justifyContent="flex-start" p={2}>
                  <SmallButton
                    onClick={() => handleExpand(item.idUID, "table")}
                    sx={{ mr: 2 }}
                    isActive={
                      expandedItem === item.idUID && contentType === "table"
                    }
                  >
                    <ExpandMore
                      sx={{
                        transform:
                          isExpanded && contentType === "table"
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s",
                      }}
                    />
                    Documenti
                  </SmallButton>

                  <SmallButton
                    onClick={() => handleExpand(item.idUID, "list")}
                    isActive={
                      expandedItem === item.idUID && contentType === "list"
                    }
                  >
                    <ExpandMore
                      sx={{
                        transform:
                          isExpanded && contentType === "list"
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s",
                      }}
                    />
                    Espandi con Elenco
                  </SmallButton>
                </Box>

                {isExpanded && (
                  <Box p={2}>
                    {contentType === "table" && (
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Colonna 1</TableCell>
                            <TableCell>Colonna 2</TableCell>
                            <TableCell>Colonna 3</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>Dato 1</TableCell>
                            <TableCell>Dato 2</TableCell>
                            <TableCell>Dato 3</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Dato 4</TableCell>
                            <TableCell>Dato 5</TableCell>
                            <TableCell>Dato 6</TableCell>
                          </TableRow>
                          {/* {[{ title: "Test" }, { title: "Test2" }].map(
                            (item, i) => {
                              return (
                                <TableRow key={i}>
                                  <TableCell>
                                    <Stack
                                      direction={"row"}
                                      alignItems={"center"}
                                    >
                                      <PictureAsPdfSharp
                                        style={{
                                          fontSize: 40,
                                          color: "#e2574c",
                                          cursor: "pointer",
                                        }}
                                      />
                                      <Typography variant="body" ml={2}>
                                        {item.title}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell>Dato 2</TableCell>
                                </TableRow>
                              );
                            }
                          )} */}
                        </TableBody>
                      </Table>
                    )}
                    {contentType === "list" && (
                      <ul>
                        <li>Elemento 1</li>
                        <li>Elemento 2</li>
                        <li>Elemento 3</li>
                      </ul>
                    )}
                  </Box>
                )}
              </CardContainer>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default ListComponent;
