import React, { useState } from "react";
import { ChipBlue, ChipOrange, ChipRed } from "../style";
import { Box, IconButton } from "@mui/material";
import { RestartAlt } from "@mui/icons-material";
import { useRef } from "react";
import PopoverRefresh from "./PopoverRefresh";

export const useColumnsDettagli = (setDataArray, codiceServizio) => {
  const [isRebootPopupOpen, setIsRebootPopupOpen] = useState(false);
  const [currentRebootRef, setCurrentRebootRef] = useState(null);
  const rebootRefs = useRef({});
  console.log(codiceServizio);

  const handleCloseRebootPopup = () => {
    setIsRebootPopupOpen(false);
  };

  const columns = (codScope) => [
    {
      field: "targa",
      headerName: "Targa",
      minWidth: 50,
      width: 120,
      renderCell: (params) => {
        const { targa, iduid, Stato } = params.row;

        const ref = (el) => {
          rebootRefs.current[params.id] = el;
        };

        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              p: 0,
            }}
          >
            {Stato !== 2 && (
              <div>
                <PopoverRefresh
                  scope={codScope}
                  idUID={iduid}
                  isRebootPopoverOpen={
                    isRebootPopupOpen &&
                    currentRebootRef === rebootRefs.current[params.id]
                  }
                  handleCloseRebootPopup={handleCloseRebootPopup}
                  rebootRef={rebootRefs.current[params.id]}
                  setDataArray={setDataArray}
                  servizio={codiceServizio}
                />
                <IconButton
                  ref={ref}
                  sx={{ p: 0, marginRight: "8px" }}
                  onClick={() => {
                    setIsRebootPopupOpen(true); // Open the popover
                    setCurrentRebootRef(rebootRefs.current[params.id]);
                  }}
                >
                  <RestartAlt color="secondary" />
                </IconButton>
              </div>
            )}
            <Box>{targa}</Box>
          </Box>
        );
      },
    },
    {
      field: "Servizio",
      headerName: "Servizio",
      minWidth: 100,
      width: 180,
      headerAlign: "left",
    },
    {
      field: "BOT",
      headerName: "BOT",
      minWidth: 100,
      width: 180,
      headerAlign: "left",
    },
    {
      field: "Stato",
      headerName: "Stato",
      width: 120,
      align: "center",
      headerAlign: "left",
      renderCell: (params) => {
        const { Stato } = params.row;
        return (
          <Box>
            {Stato === 4 ? (
              <ChipRed label="Errore" />
            ) : Stato === 5 ? (
              <ChipOrange label="Non Gestibile" />
            ) : (
              <ChipBlue label="Pending" />
            )}
          </Box>
        );
      },
    },
    {
      field: "Note",
      headerName: "Note",
      editable: true,
      minWidth: 330,
      flex: 1,
    },
    //Data Inizio - Data Fine
    {
      field: "Data",
      headerName: "Data",
      editable: true,
      minWidth: 330,
      flex: 1,
      renderCell: (params) => {
        const { DataInizio, DataFine } = params.row;

        return (
          <Box>
            {DataInizio} - {DataFine}
          </Box>
        );
      },
    },
  ];
  return columns;
};
