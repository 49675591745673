import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Divider,
  Grid,
  Typography,
  Box,
  CircularProgress,
  CardContent,
  Avatar,
  Link,
} from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import { useSelector } from "react-redux";
import { TabContent, TabsWrapper, Card, Spacer } from "./style";
import DynamicComponent from "./dynamics/DynamicComponent";
import DynamicForm from "./dynamics/DynamicForm";

export const estraiObj = (array, key) => {
  for (const obj of array) {
    if (obj.hasOwnProperty(key)) {
      return obj[key];
    }
  }
  return null;
};

const RcAutoDetails = () => {
  const { getRCAutoDetails } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [tabs, setTabs] = useState({});
  const [rcDetails, setRcDetails] = useState({});
  const [activeTab, setActiveTab] = useState("Preventivi");
  const [laterals, setLaterals] = useState({});
  const { idTarga } = useParams();
  const navigate = useNavigate();
  const role = useSelector((state) => state.role.role);
  const isAdm = ["supAdm", "adm"].includes(role);

  useEffect(() => {
    setIsLoading(true);

    getRCAutoDetails(idTarga).then((res) => {
      const { infoVeicolo, infoPiva, infoCliente, infoAgente } = res;

      res.status === 0 && navigate("/pages/rcauto");

      setRcDetails(res);
      setLaterals({ infoVeicolo, infoPiva, infoCliente, infoAgente });
      setActiveTab(Object.keys(res.tabs[0])[0]);

      const tbs = {};
      res.tabs.forEach((obj) => {
        const chiave = Object.keys(obj)[0];
        tbs[chiave] = obj[chiave];
      });

      setTabs(tbs);
      setIsLoading(false);
    });
  }, [idTarga]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getTabData = (tab) => {
    switch (tab) {
      case "Overview":
        return (
          <DynamicComponent
            nome="Overview"
            dataset={estraiObj(rcDetails?.tabs, "Overview").Cliente.dataset}
            less="Cliente"
            rcDetails={rcDetails}
          />
        );
      case "Preventivi":
        return (
          <DynamicComponent
            nome="Preventivi"
            dataset={estraiObj(rcDetails?.tabs, "Preventivi")?.dataset}
            rcDetails={rcDetails}
          />
        );
      case "Altri Rami":
        return "Altri Rami";
      default:
        return <>Tab non esistente</>;
    }
  };

  const renderTabs = () => {
    if (!tabs || typeof tabs !== "object") {
      return null;
    }

    return Object.keys(tabs).map((tabName, index) => (
      <Tab label={tabName} value={tabName} key={index} />
    ));
  };

  const handleAgentClick = () => {
    const agentId = rcDetails?.infoAgente?.iduid;
    if (agentId) {
      navigate(`/pages/profile/${agentId}`);
    }
  };

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "cornflower",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid
            container
            flex
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <Grid item xs={12} sm={6} my={4}>
              <Typography variant="h3" mb="2rem" gutterBottom display="inline">
                Dettagli targa{" "}
                {estraiObj(rcDetails.tabs, "Overview")?.Veicolo?.dataset?.map(
                  (el) => el.label === "Targa" && el.value
                )}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              container
              justifyContent="flex-end"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <Avatar
                  alt={`${rcDetails?.infoAgente?.Nome} ${rcDetails?.infoAgente?.Cognome}`}
                  src={rcDetails?.infoAgente?.Avatar}
                  sx={{ background: "#ff5722" }}
                />
              </Grid>
              <Grid item mx={2}>
                <Typography variant="h6">
                  <Link
                    component="button"
                    variant="body1"
                    onClick={handleAgentClick}
                  >
                    {rcDetails?.infoAgente?.Nome}{" "}
                    {rcDetails?.infoAgente?.Cognome}
                  </Link>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {rcDetails?.infoAgente?.azienda}
                </Typography>
              </Grid>
            </Grid>

            <Grid item width="100%">
              <Divider sx={{ mb: 6 }} />
            </Grid>

            <Card>
              <CardContent>
                <DynamicForm
                  name="Cliente"
                  isAccordion={false}
                  dataset={
                    estraiObj(rcDetails?.tabs, "Overview")?.Cliente?.dataset
                  }
                  sectName="Legale"
                  section={
                    estraiObj(rcDetails?.tabs, "Overview")?.Cliente?.section
                      ?.Legale
                  }
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid container spacing={6} direction="column">
            <Spacer sx={{ my: 6 }} />
            <Grid item xs={12} sx={{ width: "100%" }}>
              <TabsWrapper>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                >
                  {renderTabs()}
                </Tabs>
                <TabContent>
                  {getTabData(activeTab)}
                </TabContent>
              </TabsWrapper>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default RcAutoDetails;
