import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

const form = [
  {
    label: "Costo Ingresso",
    type: "textfield",
    type2: "icon",
  },
  {
    label: "Sc/Mag",
    type: "select",
    id: "scmag",
    items: [{ label: "Maggiorazione" }],
  },
  {
    label: "Tipo Sc/Mag",
    type: "select",
    id: "tipoScmag",
    items: [{ label: "%" }],
  },
  {
    label: "Valore Sc/Mag",
    type: "textfield",
    type2: "icon",
  },
];
const Opzioni = () => {
  const [isChecked, setIsChecked] = useState({
    contraente: false,
    franchigia: false,
    ratino: false,
  });

  const { contraente, franchigia, ratino } = isChecked;

  const handleChange = (event) => {
    setIsChecked({
      ...isChecked,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={4}>
        <Box>
          <Grid container spacing={4}>
            {form.map((element) =>
              element.type === "textfield" ? (
                <Grid item sm={3} xs={12} key={element.label}>
                  {element.type2 === "icon" ? (
                    <FormControl fullWidth size="small" margin="none">
                      <TextField
                        inputProps={{
                          pattern: "^\\+?[0-9]{7,15}$",
                        }}
                        id={element.id}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">€</InputAdornment>
                          ),
                        }}
                        label={element.label}
                      />
                    </FormControl>
                  ) : (
                    <TextField
                      size="small"
                      margin="none"
                      label={element.label}
                      fullWidth
                      required
                    />
                  )}
                </Grid>
              ) : (
                <Grid item sm={3} xs={12} key={element.id}>
                  <TextField
                    id={element.id}
                    fullWidth
                    select
                    label={element.label}
                    /* defaultValue="" */
                  >
                    {element.items.map((item) => (
                      <MenuItem key={item.label} value={item.label}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )
            )}
          </Grid>
        </Box>
        <Box display="flex" flexDirection="column">
          <FormControlLabel
            control={<Checkbox />}
            label="Rinuncia alla rivalsa"
          />
          <FormControlLabel
            control={<Checkbox />}
            label="con autorizzazione all'incasso"
          />
        </Box>
        <Box display="flex" flexDirection="column">
          <FormControlLabel
            control={
              <Checkbox
                checked={contraente}
                onChange={handleChange}
                name="contraente"
              />
            }
            label="Contraente"
          />
          <TextField
            disabled={!contraente ?? true}
            size="small"
            margin="none"
            label="Note"
          />
        </Box>
        <Box display="flex" flexDirection="column">
          <FormControlLabel
            control={
              <Checkbox
                checked={franchigia}
                onChange={handleChange}
                name="franchigia"
              />
            }
            label="Franchigia"
          />
          <Grid container spacing={4}>
            <Grid item sm={3} xs={12}>
              <TextField
                disabled={!franchigia ?? true}
                size="small"
                margin="none"
                label="Importo"
                fullWidth
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                disabled={!franchigia ?? true}
                size="small"
                margin="none"
                label="Valore"
                fullWidth
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                disabled={!franchigia ?? true}
                label="Scoperto"
                size="small"
                margin="none"
                select
                fullWidth
                /* defaultValue="" */
              >
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="Si">Si</MenuItem>
              </TextField>
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                disabled={!franchigia ?? true}
                size="small"
                margin="none"
                label="Note"
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" flexDirection="column">
          <FormControlLabel
            control={
              <Checkbox
                checked={ratino}
                onChange={handleChange}
                name="ratino"
              />
            }
            label="Ratino + Rinnovo"
          />
          <TextField disabled={!ratino ?? true} />
        </Box>
      </Box>
    </Box>
  );
};

export default Opzioni;
