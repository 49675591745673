import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import Tabella from "./Tabella";

const campi = [
  {
    nome: "Tipo conto",
    select: true,
    icon: false,
    regex: "",
  },
  {
    nome: "Quintali da",
    select: false,
    icon: false,
    regex: "",
  },
  {
    nome: "Quintali a",
    select: false,
    icon: false,
    regex: "",
  },
  {
    nome: "Classe da",
    select: true,
    icon: false,
    regex: "",
  },
  {
    nome: "Classe a",
    select: true,
    icon: false,
    regex: "",
  },
  {
    nome: "Prezzo",
    select: false,
    icon: true,
    regex: "",
  },
];

const Tariffe = () => {
  return (
    <Box>
      <Grid
        container
        spacing={2}
        wrap="wrap"
        display="flex"
        justifyContent="end"
      >
        {campi.map((textfield) =>
          textfield.select === true ? (
            <Grid item sm={4} md={2} xs={12} key={textfield.nome}>
              <TextField
                id={textfield.nome}
                fullWidth
                select
                label={textfield.nome}
                /* defaultValue="" */
              >
                {/* {element.items.map((item) => (
            <MenuItem key={item.label} value={item.label}>
              {item.label}
            </MenuItem>
          ))} */}
              </TextField>
            </Grid>
          ) : textfield.icon ? (
            <Grid item sm={4} md={2} xs={12} key={textfield.nome}>
              <TextField
                /* inputProps={{
                          pattern: "^\\+?[0-9]{7,15}$",
                        }} */
                id={textfield.nome}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
                fullWidth
                label={textfield.nome}
              />
            </Grid>
          ) : (
            <Grid item sm={4} md={2} xs={12} key={textfield.nome}>
              <TextField
                size="small"
                margin="none"
                label={textfield.nome}
                fullWidth
                required
              />
            </Grid>
          )
        )}
        <Grid
          item
          sm={4}
          md={2}
          xs={12}
          display="flex"
          justifyContent="end"
          marginBottom={4}
        >
          <Button variant="contained" type="submit">
            Inserisci
          </Button>
        </Grid>
      </Grid>
      <Tabella />
    </Box>
  );
};

export default Tariffe;
